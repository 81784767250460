import request from '@/utils/request'

// 获取监护规则列表
export const searchRuleList: any = (data: any) => {
    return request({
        url: `/rule/monitor/search`,
        method: 'post',
        data
    })
}

// 获取消息列表
export const searchMessage: any = (data: any) => {
    return request({
        url: `/message/template/list`,
        method: 'post',
        data
    })
}
// 新建规则
export const addMonitorRule: any = (data: any) => {
    return request({
        url: `/rule/monitor`,
        method: 'post',
        data
    })
}
// 规则详情
export const getRuleInfo: any = (id: number) => {
    return request({
        url:`/rule/monitor/info/${id}`,
        method: 'get',
    })
}





